import {
  USER_LOGIN,
  SHOW_ERROR,
  CLEAR_ERROR,
  CHECK_TOKEN,
  CHECK_CAMP_PASSWORD,
  ERROR_PASSWORD,
  CREATE_CAMPAIGN,
  REMOVE_TOKEN,
  REMOVE_CAMP_PASSWORD,
  VIEW_CAMPAIGN,
  CHECK_CSV,
  GENERATE_QR,
  LOADING_TRUE,
  LOADING_FALSE,
  EDIT_CAMPAIGN,
  REMOVE_CAMPAIGN,
  REMOVE_CSV_DATA,
} from "../type";

const initState = {
  userToken: localStorage.getItem("userToken"),
  campaignToken: localStorage.getItem("campaignToken"),
  loading: false,
  data: null,
  error: null,
  errorPassword: null,
  newCampaign: null,
  campaignData: null,
  generateQRr: null,
  newCampaignData: null,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      localStorage.setItem("userToken", action.payload.userToken);
      return {
        ...state,
        userToken: action.payload.userToken,
      };
    case CHECK_TOKEN:
      // console.log(action.payload);
      return {
        ...state,
        data: action.payload,
      };
    case REMOVE_TOKEN:
      localStorage.removeItem("userToken");
      // console.log(action.payload);
      return {
        ...state,
        removeToken: action.payload,
      };
    case SHOW_ERROR:
      // console.log(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        errorPassword: null,
        message: null,
      };
    case CHECK_CAMP_PASSWORD:
      localStorage.setItem("campaignToken", action.payload.campaignToken);
      // console.log(action.payload);
      return {
        ...state,
        campaignToken: action.payload.campaignToken,
      };
    case REMOVE_CAMP_PASSWORD:
      localStorage.removeItem("campaignToken");
      // console.log(action.payload);
      return {
        ...state,
        campaignToken: null,
        campaignData: null,
      };
    case ERROR_PASSWORD:
      // console.log(action.payload);
      return {
        ...state,
        errorPassword: action.payload,
      };
    case CREATE_CAMPAIGN:
      // console.log(action.payload);
      return {
        ...state,
        newCampaign: action.payload,
      };
    case VIEW_CAMPAIGN:
      // console.log(action.payload);
      return {
        ...state,
        campaignData: action.payload,
      };
    case CHECK_CSV:
      // console.log(action.payload);
      return {
        ...state,
        csvData: action.payload,
      };
      case REMOVE_CSV_DATA:
        // console.log(action.payload);
        return {
          ...state,
          csvData: null,
        };
    case GENERATE_QR:
      // console.log(action.payload);
      return {
        ...state,
        generateQRr: action.payload,
      };
    case EDIT_CAMPAIGN:
      // console.log(action.payload);
      return {
        ...state,
        newCampaignData: action.payload,
      };
    case REMOVE_CAMPAIGN:
      // console.log(action.payload);
      return {
        ...state,
        newCampaignData: null,
      };
    case LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
