export const USER_LOGIN = "USER_LOGIN";

export const SAVE_USERTOKEN = "SAVE_USERTOKEN";
export const CHECK_TOKEN = "CHECK_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";

export const CHECK_CAMP_PASSWORD = "CHECK_CAMP_PASSWORD";
export const REMOVE_CAMP_PASSWORD = "REMOVE_CAMP_PASSWORD";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const VIEW_CAMPAIGN = "VIEW_CAMPAIGN";
export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN";

export const CHECK_CSV = "CHECK_CSV";
export const REMOVE_CSV_DATA = "REMOVE_CSV_DATA";
export const GENERATE_QR = "GENERATE_QR";
export const DOWNLOAD_QR = "DOWNLOAD_QR";

export const LOADING_TRUE = "LOADING_TRUE";
export const LOADING_FALSE = "LOADING_FALSE";

export const SHOW_ERROR = "SHOW_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const ERROR_PASSWORD = "ERROR_PASSWORD";

export const REMOVE_CAMPAIGN = "REMOVE_CAMPAIGN";
