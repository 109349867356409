import React, { Suspense, lazy, useState } from "react";
import "antd/dist/antd.min.css";
import { Layout, Menu, Input, Modal, Button } from "antd";
import SideBar from "./components/Sidebar/SideBar";

import { LogoutOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import { removeToken } from "./redux/actions/userActions";

const LoginPage = lazy(() => import("./pages/Login/LoginPage.js"));
const HomePage = lazy(() => import("./pages/Home/HomePage.js"));

const CreateCampaign = lazy(() => import("./pages/Campaign/CreateCampaign"));
const CampaignSetting = lazy(() => import("./pages/Campaign/GenerateQR"));
const CampaignOverview = lazy(() =>
  import("./pages/Campaign/campaignOverview")
);
const EditCampaignData = lazy(() =>
  import("./pages/Campaign/EditCampaignData")
);

const { Header, Content, Sider } = Layout;

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pop, setPop] = useState(false);
  const [value, setValue] = useState("");

  const handleClose = () => {
    setPop(false);
  };

  const handleLogOut = () => {
    dispatch(removeToken({}));
  };
  return (
    <Suspense fallback={"....."}>
      {!window.location.pathname.includes("home") &&
      !window.location.pathname.includes("report") &&
      !window.location.pathname.includes("campaign") ? (
        <Routes>
          <Route exact path="/welcome/login" element={<LoginPage />} />
          <Route exact path="*" element={<Navigate to="/welcome/login" />} />
        </Routes>
      ) : null}

      {window.location.pathname.includes("home") ? (
        <Layout className="site-layout">
          <Header className="site-layout-background">
            <div
              style={{
                display: "flex",
                width: "50vw",
                justifyContent: "flex-start",
              }}>
              <div
                style={{
                  fontWeight: "bold",
                  color: "white",
                  fontSize: "25px",
                }}>
                QR Code Generator
              </div>
              <div style={{ paddingLeft: "5%" }}>
                <Input
                  style={{ width: 300 }}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}></Input>
              </div>
            </div>

            <a
              onClick={handleLogOut}
              href="/welcome/login"
              style={{
                fontWeight: "bold",
                color: "white",
                fontSize: "30px",
              }}>
              <LogoutOutlined />
            </a>
          </Header>

          <Content
            style={{
              margin: "1%",
              // overflow: 'initial',
            }}>
            <div
              style={{
                textAlign: "center",
                background: " #e7eff6 ",
                minHeight: "91vh",
              }}>
              <Routes>
                <Route exact path="/home" element={<HomePage value={value}/>} />

                <Route
                  path="*"
                  element={<Navigate to="/welcome/login" />}></Route>
              </Routes>
            </div>
          </Content>
        </Layout>
      ) : null}

      {window.location.pathname.includes("report") ? (
        <Layout className="site-layout">
          <Header className="site-layout-background">
            <div
              style={{
                display: "flex",
                width: "50vw",
                justifyContent: "flex-start",
              }}>
              <div
                style={{
                  fontWeight: "bold",
                  color: "white",
                  fontSize: "25px",
                }}>
                QR Code Generator
              </div>
            </div>

            <a
              onClick={handleLogOut}
              href="/welcome/login"
              style={{
                fontWeight: "bold",
                color: "white",
                fontSize: "30px",
              }}>
              <LogoutOutlined />
            </a>
          </Header>
          <Layout hasSider>
            <Content
              style={{
                margin: "1%",
                // overflow: 'initial',
              }}>
              <div
                style={{
                  textAlign: "center",
                  background: " #e7eff6 ",
                  minHeight: "91vh",
                }}>
                <Routes>
                  <Route exact path="/home" element={<HomePage />} />
                  <Route
                    exact
                    path="/report/createCampaign"
                    element={<CreateCampaign />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to="/welcome/login" />}></Route>
                </Routes>
              </div>
            </Content>
          </Layout>
        </Layout>
      ) : null}

      {window.location.pathname.includes("campaign") ? (
        <Layout className="site-layout">
          <Header className="site-layout-background">
            <div
              style={{
                display: "flex",
                width: "50vw",
                justifyContent: "flex-start",
              }}>
              <div
                style={{
                  fontWeight: "bold",
                  color: "white",
                  fontSize: "25px",
                }}>
                QR Code Generator
              </div>
            </div>

            <a
              onClick={handleLogOut}
              href="/welcome/login"
              style={{
                fontWeight: "bold",
                color: "white",
                fontSize: "30px",
              }}>
              <LogoutOutlined />
            </a>
          </Header>
          <Layout hasSider>
            <Sider
              style={{
                overflow: "auto",
                minheight: "80vh",
                maxheight: "80%",
                // position: 'fixed',  //button will gone
                left: 0,
                top: 0,
                bottom: 0,
              }}
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}>
              <div className="logo" />
              <Menu
                theme="dark"
                mode="inline"
                items={SideBar}
                onClick={(e) => {
                  // console.log(e.key);
                  // if (e.key === "/home") {
                  //   setPop(true);
                  // } else {
                  navigate(e.key);
                }}></Menu>
            </Sider>
            <Content
              style={{
                margin: "1%",
                // overflow: 'initial',
              }}>
              <div
                style={{
                  textAlign: "center",
                  background: " #e7eff6 ",
                  minHeight: "91vh",
                }}>
                <Routes>
                  <Route exact path="/home" element={<HomePage />} />
                  <Route
                    exact
                    path="/campaign/createCampaign"
                    element={<CreateCampaign />}
                  />
                  <Route
                    exact
                    path="/campaign/campaignOverview"
                    element={<CampaignOverview />}
                  />

                  <Route
                    exact
                    path="/campaign/editCampaignData"
                    element={<EditCampaignData />}
                  />

                  <Route
                    exact
                    path="/campaign/CampaignSetting"
                    element={<CampaignSetting />}
                  />

                  <Route
                    path="*"
                    element={<Navigate to="/welcome/login" />}></Route>
                </Routes>
              </div>
              <Modal
                open={pop}
                centered
                header={null}
                footer={null}
                onCancel={handleClose}
                closable={false}
                className="modal-style"
                style={{ borderRadius: " 100px !important" }}>
                <div className="home-modal-container">
                  <div className="home-modal-header">
                    Are you sure to back home?{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "right",
                      paddingTop: "2%",
                    }}>
                    <Button
                      className="home-modal-container-button"
                      onClick={() => setPop(false)}>
                      Nope
                    </Button>
                    <Button
                      className="home-modal-container-button"
                      onClick={() => navigate("/home")}>
                      Yes
                    </Button>
                  </div>
                </div>
              </Modal>
            </Content>
          </Layout>
        </Layout>
      ) : null}
    </Suspense>
  );
}

export default App;
