import React from "react";
import * as AiIcons from "react-icons/ai";

const SideBar = [
  {
    label: "Home",
    path: "/home",
    key: "/home",
    icon: <AiIcons.AiFillHome />,
  },
  {
    label: "Campaign Overview",
    path: "/campaign/campaignOverview",
    key: "/campaign/campaignOverview",
    icon: <AiIcons.AiFillPieChart />,
  },
  {
    label: "Generate QR Code",
    path: "/campaign/CampaignSetting",
    key: "/campaign/CampaignSetting",
    icon: <AiIcons.AiFillPlusCircle />,
  },
  {
    label: "Edit Campaign Data",
    path: "/campaign/editCampaignData",
    key: "/campaign/editCampaignData",
    icon: <AiIcons.AiOutlineEdit />,
  },
];

export default SideBar;
