import {
  USER_LOGIN,
  SHOW_ERROR,
  CLEAR_ERROR,
  CHECK_TOKEN,
  CHECK_CAMP_PASSWORD,
  ERROR_PASSWORD,
  CREATE_CAMPAIGN,
  REMOVE_TOKEN,
  REMOVE_CAMP_PASSWORD,
  VIEW_CAMPAIGN,
  CHECK_CSV,
  GENERATE_QR,
  LOADING_TRUE,
  LOADING_FALSE,
  DOWNLOAD_QR,
  EDIT_CAMPAIGN,
  REMOVE_CAMPAIGN,
  REMOVE_CSV_DATA,
} from "../type";
import axios from "axios";

export const userLogin = (data) => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });

  axios
    .post("/api/auth/login", data)
    .then((res) => {
      dispatch({ type: USER_LOGIN, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
    });
};

export const checkUserToken = (data) => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });

  axios
    .post("/api/auth/checkToken", data)
    .then((res) => {
      dispatch({ type: CHECK_TOKEN, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
    });
};

export const checkCampPassword = (data) => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });

  axios
    .post("/api/user/loginCampaign", data)
    .then((res) => {
      dispatch({ type: CHECK_CAMP_PASSWORD, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: ERROR_PASSWORD, payload: err.response.data.error });
    });
};

export const createCampaign = (data) => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });

  axios
    .post("/api/user/createCampaign", data)
    .then((res) => {
      dispatch({ type: CREATE_CAMPAIGN, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
    });
};

export const checkCampaignToken = (data) => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });

  axios
    .post("/api/user/checkCampaignToken", data)
    .then((res) => {
      dispatch({ type: VIEW_CAMPAIGN, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
    });
};

export const checkCsv = (data) => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
  dispatch({ type: LOADING_TRUE });

  axios
    .post("/api/user/uploadCsv", data)
    .then((res) => {
      dispatch({ type: LOADING_FALSE });
      dispatch({ type: CHECK_CSV, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING_FALSE });
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
    });
};

export const generateQR = (data) => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });

  axios
    .post("/api/user/generateQR", data)
    .then((res) => {
      dispatch({ type: GENERATE_QR, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
    });
};

export const downloadQR = (data) => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });

  axios
    .post("/api/user/downloadQRCode", data)
    .then((res) => {
      window.location = res.data.url;
    })
    .catch((err) => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
    });
};

export const editCampaignData = (data) => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });

  axios
    .post("/api/user/editCampaign", data)
    .then((res) => {
      dispatch({ type: EDIT_CAMPAIGN, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
    });
};

export const removeToken = (data) => {
  return {
    type: REMOVE_TOKEN,
    payload: data,
  };
};

export const removeCampaignToken = (data) => {
  return {
    type: REMOVE_CAMP_PASSWORD,
    payload: data,
  };
};

export const removeCampaign = (data) => {
  return {
    type: REMOVE_CAMPAIGN,
    payload: data,
  };
};

export const removecsvData = (data) => {
  return {
    type: REMOVE_CSV_DATA,
    payload: data,
  };
};